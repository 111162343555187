import { GetCurrentUrl } from '../history';

var page_referrer: string | undefined = GetCurrentUrl();

const TrackGoogleAnalyticsPageView = (page: string) => {    
    if (!window.gtag) {
        return;
    }

    // Gtag changes
    window.gtag('set', 'page_location', window.location.protocol + "//" + window.location.host + page);
    if (page_referrer) {
        window.gtag('set', 'page_referrer', window.location.protocol + "//" + window.location.host + page_referrer);
    }    

    window.gtag('event', 'page_view');

    page_referrer = page;
    return;
}
const TrackGoogleAnalyticsEvent = (eventName: string, params?: any, eventCallback?: (() => void) | undefined) => {
    if (!params) {
        params = {};
    }

    if (eventCallback) {
        params = { 
            ...params, 
            'event_callback': eventCallback,
            'event_timeout': 2000
        }
    }

    if (window.gtag) {
        window.gtag('event', eventName, params);
    }
    else {
        if (eventCallback) {
            eventCallback();
        }
    }
}
const BindGoogleAnalyticsEventClicks = (element: HTMLElement) => {
    var linkClickEvents = element.querySelectorAll<HTMLAnchorElement | HTMLButtonElement>("a[data-ga4-event],button[data-ga4-event]");

    if (linkClickEvents) {
        linkClickEvents.forEach(link => {
            
            link.addEventListener("click", function(event) {

                var ga4EventClick = event.target as HTMLAnchorElement | HTMLButtonElement;

                if (ga4EventClick) {
                    // Find the parameters
                    var params: any = {};

                    if (ga4EventClick.tagName?.toLowerCase() == "a") {
                        var href = ga4EventClick.getAttribute("href");

                        if (!href || href == "#") {
                            return;
                        }

                        params['link_page_href'] = href;
                    }

                    for (var tt=1; tt<=ga4EventClick.attributes.length; tt++) {
                        var attribute = ga4EventClick.attributes[tt-1];

                        if (attribute.name.indexOf("data-ga4-event-", 0) >= 0) {
                            var name = attribute.name;
                            name = name.replace(/^data\-ga4\-event\-/ig, "");
                            name = name.replace(/-/ig, "_");

                            let value: string | number = attribute.value;

                            if (value && !isNaN(Number(value))) {
                                value = Number(value);
                            }

                            params[name] = value;
                        }
                    }  

                    TrackGoogleAnalyticsEvent(ga4EventClick.getAttribute("data-ga4-event") ?? "", params);                    
                                    
                    if (ga4EventClick.getAttribute("href") && (ga4EventClick.getAttribute("href") ?? "").indexOf("#") >= 0) {
                        var idSplit = (ga4EventClick.getAttribute("href") ?? "").split(/\#/ig);

                        if (idSplit.length == 2) {
                            var id = idSplit[1];

                            var idElement = document.getElementById(id);

                            if (idElement) {
                                var headerBar = document.getElementsByTagName("header") as HTMLCollectionOf<HTMLDivElement>;

                                let idElementTop = idElement!.getBoundingClientRect().top - 15;
                                if (headerBar && getComputedStyle(headerBar[0]).position == "sticky") {
                                    idElementTop -= 70;
                                }

                                event.preventDefault();
                                window.scrollTo({ top: idElementTop + window.scrollY, behavior: 'smooth' });    
                            }
                        }
                        
                    }
                }
                
            })
        });
    }
}

const BindGoogleAnalyticsTextBlockBlockGridLinks = () => {
    var blockGridLinks = document.querySelectorAll<HTMLAnchorElement>("div.block-grid.text-block a:not([data-ga4-event])");

    if (blockGridLinks) {
        blockGridLinks.forEach(link => {
            link.setAttribute("data-ga4-event", "text_block_link_click");
            link.setAttribute("data-ga4-event-type", "Block grid");            
        })
        if (document.querySelectorAll("div.block-grid.text-block")) {
            var textBlocks = document.querySelectorAll("div.block-grid.text-block");

            for (var tt=1; tt<= textBlocks.length; tt++) {
                var textBlock = textBlocks[tt-1] as HTMLDivElement

                BindGoogleAnalyticsEventClicks(textBlock);
            }
        }
    }    

}

export { TrackGoogleAnalyticsPageView, TrackGoogleAnalyticsEvent, BindGoogleAnalyticsEventClicks, BindGoogleAnalyticsTextBlockBlockGridLinks }